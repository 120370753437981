// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/xiaApp1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".news[data-v-764a0da0]{overflow-y:hidden;height:100%;position:relative}[data-v-764a0da0] .van-tabs--line{height:105%;position:relative}[data-v-764a0da0] .van-tabs--line .van-tabs__wrap{border-bottom:1px solid #e9e9e9!important;z-index:3;height:10.667vw}[data-v-764a0da0] .van-tabs--line .van-tabs__wrap .van-tab--active{font-size:4.267vw!important;font-weight:600;font-family:PingFang SC-Medium,PingFang SC;height:100%;position:relative}[data-v-764a0da0] .van-tabs--line .van-tabs__wrap .van-tab--active .van-tab__text{color:#704208;font-weight:500;font-size:4.8vw}[data-v-764a0da0] .van-tabs--line .van-tabs__wrap .van-tab{font-size:3.467vw;font-family:PingFang SC-Medium,PingFang SC;line-height:10.667vw;color:#704208}[data-v-764a0da0] .van-tabs__nav.van-tabs__nav--line{z-index:2;background:linear-gradient(270deg,#dca765,#efcdab)!important}[data-v-764a0da0] .van-tabs__line{background-color:#704208}[data-v-764a0da0] .van-tabs__content,[data-v-764a0da0] .van-tabs__content .van-tab__pane{height:100%}.foot[data-v-764a0da0]{width:100%;height:14.4vw;background-color:red;z-index:2;position:fixed;bottom:13.333vw;left:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.foot .app[data-v-764a0da0]{display:block;width:24vw;height:8.533vw;background-color:#ffb606;position:absolute;color:#fff;top:50%;margin-top:-4.8vw;right:5.333vw;text-align:center;line-height:8.533vw;border-radius:8.533vw;font-size:4vw}.iframeOne[data-v-764a0da0]{position:absolute;top:-2.667vw;z-index:1;height:90%}@media screen and (max-height:800px){.iframe[data-v-764a0da0]{position:absolute;bottom:5.1rem;z-index:1;height:102%}}@media screen and (min-height:800px){.iframe[data-v-764a0da0]{position:absolute;bottom:6rem;z-index:1;height:100%}}#loadingbox[data-v-764a0da0]{margin-top:20vw}", ""]);
// Exports
module.exports = exports;
